export const DATE_FORMAT = 'DD.MM.YYYY';
export const DATE_FORMAT_HHMM = 'DD.MM.YYYY HH:mm';
export const DATE_FORMAT_COMMA_HHMM = 'DD.MM.YYYY, HH:mm';
export const HH_MM = 'HH:mm';
export const SERVER_DATE_FORMAT = 'YYYY-MM-DD';
export const SERVER_DATE_FORMAT_HHMM = 'YYYY-MM-DD HH:mm';
export const SERVER_DATE_FORMAT_HHMMSS = 'YYYY-MM-DD HH:mm:ss';

export const INPUT_TYPE = {
    TEXT: 'text',
    PASSWORD: 'password',
    SEARCH: 'search',
    TEXTAREA: 'textarea',
    NUMBER: 'number',
};

export const INPUT_SIZE = {
    BIG: 'big',
    SMALL: 'small',
};

export const BUTTON_SIZE = {
    BIG: 'big',
    SMALL: 'small',
};

export const BUTTON_COLOR = {
    BLUE: 'blue',
    BLUE200: 'blue200',
    RED: 'red',
    GRAY: 'gray',
};

export const AVATAR_TYPE = {
    USER_BIG: 'big',
    USER_SMALL: 'small',
    USER_PROFILE: 'profile',
    PROJECT_SMALL: 'project',
};

export const USER_META = {
    TASKS_OPTIONS: 'tasksOptions',
    TASKS_CONFIG: 'tasksConfig',
    PROJECTS_OPTIONS: 'projectsOptions',
    PROJECTS_PROCESSES_OPTIONS: 'projectsProcessesOptions',
    USERS_OPTIONS: 'usersOptions',
};

export const ORDER_BY = {
    ASC: 'asc',
    DESC: 'desc',
};

export const LOADERS = {
    TASK_LIST: 'taskList',
    PROJECT_LIST: 'projectList',
    TASK_PROJECT_LIST: 'taskProjectList',
    SPRINT: 'sprint',
    SPRINT_TASKS_LIST: 'sprintList',
    SPRINT_BACKLOG_LIST: 'sprintBacklogList',
    USER_LIST: 'userList',
    TASK_COMMENT: 'taskComment',
    WIKI: 'wiki',
    GROUP: 'group',
    GROUP_LIST: 'groupList',
    SET_PASSWORD: 'setPassword',
    CONFIRM_EMAIL: 'confirmEmail',
    SEND_CONFIRM_EMAIL: 'sendConfirmEmail',
};

export const LOADERS_TYPE = {
    LOADING: 'loading',
    ADDITIONAL_LOADING: 'additionalLoading',
};

export const TASK_FIELDS = {
    PRIORITY: 'priority',
    DIFFICULTY: 'difficulty',
    EDATE: 'edate',
    EXECUTIVE: 'executive',
    SPRINT: 'sprint_id',
    STATUS: 'status',
    TYPE: 'type',
    WDATE: 'wdate',
    TITLE: 'title',
    DESCRIPTION: 'text',
    TAGS: 'tags',
    LABEL: 'label',
    TIMER: 'timer',
    ASSIGNEES: 'assignees',
    NOTEST: 'notest',
    OBSERVERS: 'observers',
    CREATED_AT: 'created_at',
    UPDATED_AT: 'updated_at',
};

export const PROJECT_FIELDS = {
    GROUP_ID: 'group_id',
    CLIENT: 'client',
    MANAGER: 'manager',
    TESTER: 'tester',
    TITLE: 'title',
    DESCRIPTION: 'text',
    ARCHIVE: 'archive',
    USERS: 'users',
    WIKI: 'wiki',
    STATUSES: 'statuses',
    USE_SYSTEM_STATUS: 'use_system_status',
    TAGS: 'tags',
};

export const USER_FIELDS = {
    AVATAR: 'avatar',
    ABOUT: 'about',
    NAME: 'name',
    EMAIL: 'email',
    POST: 'post',
    WPHONE: 'wphone',
    SKYPE: 'skype',
    RATE: 'rate',
    SALARY: 'salary',
    ROLE_ID: 'role_id',
    CALENDAR_NORMA: 'calendar_norma',
    STATUS: 'status',
    CALENDAR_ON: 'calendar_on',
    USE_LABELS: 'use_labels',
    SYSTEM_SUBSCRIPTION: 'system_subscription',
    INFO_SUBSCRIPTION: 'info_subscription',
};

export const SPRINT_FIELDS = {
    TITLE: 'title',
    DATE_START: 'date_start',
    DATE_FINISH: 'date_finish',
    STATUS: 'status',
};

export const ICON = {
    STATUS: 'status',
    PRIORITY: 'priority',
    TYPE: 'type',
    SPRINT: 'sprint',
};

export const TOAST = {
    SUCCESS: 'success',
    ERROR: 'error',
    NOTIFICATION: 'notification',
    SYSTEM: 'system',
};

export const NOTIFICATION = {
    INFO: 'info',
    WARDEN: 'warden',
    SYSTEM: 'system',
};

export const SOCKET_MODEL = {
    TASK: 'task',
    TIMING: 'timing',
    NOTIFICATION: 'notification',
};

export const TASK_TYPES = {
    MEETS: 6,
};

export const TASK_STATUS = {
    NEW: 0,
    AWAITS: 1,
    IN_WORK: 2,
    ACCEPTED: 3,
    CANCELED: 4,
    CLARIFYING: 5,
    COMPLETED: 6,
    BACKLOG: 7,
    TESTING: 8,
    REVIEW: 9,
    TECH_REVIEW: 10,
};

export const TASK_LIST_STATUS = {
    ALL: 'all',
    MY: 'my',
    OPEN: 'open',
};

export enum IFileUploadStatus {
    FAILED = 'failed',
    PROCESSING = 'processing',
    SUCCESS = 'success',
}

export enum TASK_STATUS_NAME {
    'Новая',
    'Ожидает',
    'В разработке',
    'Принята',
    'Отменена',
    'Уточняется',
    'Выполнена',
    'Беклог',
    'Тестирование',
    'Ревью',
    'Тех. ревью',
}

export const BACKGROUND = {
    TRACK_DEFAULT: '#51B97C',
    TRACK_WORKING: '#4B81E3',
};

export const TASK_CONTROLS = {
    START: 'START',
    STOP: 'STOP',
};

export const PLACE = {
    TOP: 'TOP',
    BOTTOM: 'BOTTOM',
    LEFT: 'LEFT',
    RIGHT: 'RIGHT',
    CENTER: 'CENTER',
};

export const META = {
    CURRENT_GROUP: 'currentGroup',
    PASSWORD_SET: 'passwordSet',
    PROJECTS: 'projects',
};

export const TABLE = {
    TASK_LIST: 'taskList',
    PROJECT_LIST: 'projectList',
    USER_LIST: 'userList',
    PROJECT_USERS: 'projectUsers',
};

export enum INVITATIONS {
    NOT_INVITED,
    INVITED,
}

export enum GROUP_ROLE {
    OWNER = 1,
    MANAGER,
    MEMBER,
    OBSERVER,
}

export const GROUP_ROLES = {
    1: 'Владелец',
    2: 'Менеджер',
    3: 'Участник',
    4: 'Наблюдатель',
};

export const KEYBOARD = {
    ESC: 'Escape',
};

export enum MEET_STATUS {
    MAYBE = 1,
    ACCEPT,
    DECLINE,
}

export const MEET_STATUSES = {
    1: 'maybe',
    2: 'accept',
    3: 'decline',
};

export const MEET_NAME = {
    0: 'не ответил',
    1: 'может быть',
    2: 'пойдет',
    3: 'не пойдет',
};

export const TASK_MARKS = {
    FRESH: 'fresh',
    HOT: 'hot',
    DELAYED: 'delayed',
    CURRENT: 'current',
    CLARIFYING: 'clarifying',
    REVIEW: 'review',
    TECH_REVIEW: 'techReview',
};

export const MARK_NAME = {
    fresh: 'Новая',
    hot: 'Горящая',
    delayed: 'Просроченная',
    current: 'Текущая',
    clarifying: 'На уточнении',
    review: 'Ревью',
    techReview: 'Тех. ревью',
};

export enum PROJECT_ROLE {
    OWNER = 1,
    MANAGER,
    MEMBER,
    OBSERVER,
}

export const PROJECT_ROLES = {
    1: 'Владелец',
    2: 'Менеджер',
    3: 'Участник',
    4: 'Наблюдатель',
};

export const MULTI_SELECT = {
    TEXT: 'TEXT',
    USER: 'USER',
    STATUS: 'STATUS',
};

export enum REPEAT_INTERVAL {
    day = 'день',
    week = 'неделя',
    month = 'месяц',
    year = 'год',
}

export const REPEAT_INTERVAL_TYPE = {
    day: 'day',
    week: 'week',
    month: 'month',
    year: 'year',
};
