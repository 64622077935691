import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import {
    createSprint,
    getSprint,
    loadBacklogIds,
    updateSprint,
} from '~/store/slices/sprint/reducers';
import { getTaskList } from '~/store/slices/taskList/reducers';
import { getTask } from '~/store/slices/task/reducers';

export const sprintsAdapter = createEntityAdapter<ISprint>();

export interface ISprintState {
    loadedIds: number[];
    entities: Record<number, ISprint[]>;
    totalCount: 0;
    backlog: {
        ids: number[];
        totalCount: number;
    };
    tasksIds: number[];
}

export const sprintsSlice = createSlice({
    name: 'sprints',
    initialState: {
        ...sprintsAdapter.getInitialState(),
        totalCount: 0,
        viewedCount: 0,
        backlog: {
            ids: [],
            totalCount: 0,
        },
        tasksIds: [],
    },
    reducers: {
        create: sprintsAdapter.setMany,
        add: sprintsAdapter.addMany,
        update: sprintsAdapter.upsertMany,
        remove: sprintsAdapter.removeMany,
        clear: sprintsAdapter.removeAll,
        updateLists: (state, action) => {
            console.log(action.payload);
            if (action.payload.backlogIds) {
                console.log('backlog updated');
                state.backlog.ids = action.payload.backlogIds;
            }
            if (action.payload.sprintIds) {
                console.log('sprint updated');
                state.tasksIds = action.payload.sprintIds;
            }
        },
    },
    extraReducers: (builder) => {
        builder.addCase(createSprint.fulfilled, (state, action) => {
            sprintsAdapter.upsertOne(state, { ...action.payload.sprint });
        });
        builder.addCase(getSprint.fulfilled, (state, action) => {
            sprintsAdapter.upsertOne(state, { ...action.payload.sprint, fullData: true });
            if (action.payload.sprint.tasks) {
                state.tasksIds = action.payload.sprint.tasks.map((task: ITask) => task.id);
            }
        });
        builder.addCase(updateSprint.fulfilled, (state, action) => {
            sprintsAdapter.upsertOne(state, { ...action.payload.sprint });
        });
        builder.addCase(loadBacklogIds.fulfilled, (state, action) => {
            if (action.payload) {
                state.backlog.ids = action.payload.rows.map((task: ITask) => task.id);
                state.backlog.totalCount = action.payload.totalCount;
            }
        });
        builder.addCase(getTaskList.fulfilled, (state, action) => {
            if (action.payload) {
                action.payload.rows.map((task: ITask) => {
                    task?.project?.sprints.map((sprint) => {
                        sprintsAdapter.upsertOne(state, { ...sprint });
                    });
                });
            }
        });
        builder.addCase(getTask.fulfilled, (state, action) => {
            if (action.payload) {
                action.payload.task?.project?.sprints.map((sprint: ISprint) => {
                    sprintsAdapter.upsertOne(state, { ...sprint });
                });
            }
        });
    },
    selectors: {
        selectSprints: (sliceState) => sliceState.entities,
        selectSprint: (sliceState, id: number) => sliceState.entities[id],
        selectBacklogIds: (sliceState) => sliceState.backlog.ids,
        selectBacklogTotalCount: (sliceState) => sliceState.backlog.totalCount,
        selectSprintTasksIds: (sliceState) => sliceState.tasksIds,
    },
});

export type tasksSlice = {
    [sprintsSlice.name]: ReturnType<typeof sprintsSlice['reducer']>;
};
export const { create, update, remove, clear, add, updateLists } = sprintsSlice.actions;
export const {
    selectSprints,
    selectSprint,
    selectBacklogIds,
    selectBacklogTotalCount,
    selectSprintTasksIds,
} = sprintsSlice.selectors;
