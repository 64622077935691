import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { addTaskTimings } from '~/store/slices/task/reducers';

export const timingAdapter = createEntityAdapter<ITiming>();

export interface ITimingState {
    entities: Record<number, ITiming[]>;
}

export const timingSlice = createSlice({
    name: 'timing',
    initialState: {
        ...timingAdapter.getInitialState(),
    },
    reducers: {
        create: timingAdapter.setMany,
        add: timingAdapter.addMany,
        update: timingAdapter.upsertMany,
        remove: timingAdapter.removeMany,
        clear: timingAdapter.removeAll,
    },
    extraReducers: (builder) => {
        builder.addCase(addTaskTimings.fulfilled, (state, action) => {
            if (action.payload) {
                action.payload.timings.map((timing: ITiming) => {
                    timingAdapter.upsertOne(state, { ...timing });
                });
            }
        });
    },
    selectors: {
        selectTimings: (sliceState) => sliceState.entities,
        selectTiming: (sliceState, id: number) => sliceState.entities[id],
        selectTimingsArray: (sliceState) => sliceState && Object.values(sliceState.entities),
    },
});

export type timingSlice = {
    [timingSlice.name]: ReturnType<typeof timingSlice['reducer']>;
};
export const { create, update, remove, clear, add } = timingSlice.actions;
export const { selectTimings, selectTiming, selectTimingsArray } = timingSlice.selectors;
