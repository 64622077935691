import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import {
    createPage,
    deleteFilesFromWikiPage,
    getWiki,
    loadPage,
    removePage,
    updatePage,
    uploadFilesToWikiPage,
} from '~/store/slices/wiki/reducers';

export const wikiAdapter = createEntityAdapter<IWikiPage>();

export interface IWikiState {
    entities: Record<number, IWikiPage[]>;
    totalCount: 0;
}

export const wikiSlice = createSlice({
    name: 'wiki',
    initialState: {
        ...wikiAdapter.getInitialState(),
        totalCount: 0,
    },
    reducers: {
        create: wikiAdapter.setMany,
        add: wikiAdapter.addMany,
        update: wikiAdapter.upsertMany,
        remove: wikiAdapter.removeMany,
        clear: wikiAdapter.removeAll,
    },
    extraReducers: (builder) => {
        builder.addCase(removePage.fulfilled, (state, action) => {
            if (action.payload) {
                wikiAdapter.removeOne(state, action.meta.arg);
            }
        });
        builder.addCase(loadPage.fulfilled, (state, action) => {
            if (action.payload) {
                const page = {
                    ...action.payload.page,
                    files: action.payload.files,
                };
                wikiAdapter.upsertOne(state, page);
            }
        });
        builder.addCase(createPage.fulfilled, (state, action) => {
            if (action.payload) {
                wikiAdapter.upsertOne(state, action.payload.page);
            }
        });
        builder.addCase(updatePage.fulfilled, (state, action) => {
            if (action.payload) {
                wikiAdapter.upsertOne(state, action.payload.page);
            }
        });
        builder.addCase(getWiki.fulfilled, (state, action) => {
            if (action.payload) {
                wikiAdapter.upsertMany(state, action.payload.pages);
            }
        });
        builder.addCase(uploadFilesToWikiPage.fulfilled, (state, action) => {
            const page = state.entities[action.meta.arg.pageId];
            if (page && action.payload) {
                page.files = page.files
                    ? [...page.files, ...action.payload.files]
                    : action.payload.files;
                wikiAdapter.upsertOne(state, page);
            }
        });
        builder.addCase(deleteFilesFromWikiPage.fulfilled, (state, action) => {
            const page = state.entities[action.meta.arg.pageId];
            const fileId = action.meta.arg.fileId;
            if (page) {
                page.files = page.files ? [...page.files.filter((file) => file.id !== fileId)] : [];
                wikiAdapter.upsertOne(state, page);
            }
        });
    },
    selectors: {
        selectTotalCount: (sliceState) => sliceState.totalCount,
        selectWikiPage: (sliceState, id: number) => sliceState.entities[id],
        selectWikiPages: (sliceState, projectId: number) =>
            Object.values(sliceState.entities).filter((page) => page.project_id === projectId),
    },
});

export type wikiSlice = {
    [wikiSlice.name]: ReturnType<typeof wikiSlice['reducer']>;
};

export const { create, update, remove, clear, add } = wikiSlice.actions;
export const { selectTotalCount, selectWikiPage, selectWikiPages } = wikiSlice.selectors;
