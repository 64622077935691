import { createSlice } from '@reduxjs/toolkit';
import { getAccount, updateFilter, updateGroup } from '~/store/slices/account/reducers';
import { removeTokens } from '~/utils/storage';
import { updateUser } from '~/store/slices/user/reducers';

export interface IAccountState {
    todayWorkedTime: number;
    isCalendarOn: boolean;
    userId: number;
    account: IAccount;
    isLogging: boolean;
}

const initialState: IAccountState = {
    todayWorkedTime: 0,
    isCalendarOn: true,
    userId: 0,
    account: {
        projects_invites: [],
        is_email_valid: true,
        filters: [],
        groups: [],
    },
    isLogging: true,
};

export const accountSlice = createSlice({
    name: 'account',
    initialState,
    reducers: {
        updateUserProjectInvite(state, action) {
            state.account.projects_invites = state.account.projects_invites?.map((invite) =>
                invite.id === action.payload.id
                    ? {
                          ...invite,
                          ...action.payload,
                      }
                    : invite,
            );
        },
        addUserProjectInvite(state, action) {
            state.account.projects_invites = [
                ...(state.account.projects_invites ?? []),
                ...action.payload,
            ];
        },
        removeUserProjectInvite(state, action) {
            state.account.projects_invites = (state.account.projects_invites ?? []).filter(
                (group) => group.id !== action.payload.id,
            );
        },
        updateUserGroup(state, action) {
            state.account.groups = state.account.groups?.map((group) =>
                group.id === action.payload.id
                    ? {
                          ...group,
                          ...action.payload,
                      }
                    : group,
            );
        },
        addUserGroup(state, action) {
            state.account.groups = [...(state.account.groups ?? []), ...action.payload];
        },
        removeUserGroup(state, action) {
            state.account.groups = (state.account.groups ?? []).filter(
                (group) => group.id !== action.payload.id,
            );
        },
        logoutAccount(state) {
            state.account = initialState.account;
            state.userId = 0;
        },
        increaseWorkedTime(state) {
            state.account.workedTime = (state.account.workedTime || 0) + 60;
        },
        changeIsEmailValid(state, action) {
            state.account.is_email_valid = action.payload;
        },
        changeIsCalendarOn(state, action) {
            state.isCalendarOn = action.payload;
        },
        changeTodayWorkedTime(state, action) {
            state.todayWorkedTime = action.payload;
        },
        changeCurrentUserId(state, action) {
            state.userId = action.payload;
        },
        updateCurrentTask(state, action) {
            state.account.currentTask = action.payload;
        },
        clearAccount(state) {
            state.account = initialState.account;
            state.userId = initialState.userId;
            removeTokens();
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getAccount.fulfilled, (state, action) => {
            state.isLogging = false;
            if (action.payload) {
                state.account = action.payload;
                state.isLogging = false;
            }
        });
        builder.addCase(getAccount.pending, (state) => {
            state.isLogging = true;
        });
        builder.addCase(getAccount.rejected, (state) => {
            state.isLogging = false;
        });
        builder.addCase(updateUser.fulfilled, (state, action) => {
            if (action.payload && action.payload.user.id === state.userId) {
                //TODO: Обновить данные профиля тут если обновились у пользователя
            }
        });
        builder.addCase(updateGroup.fulfilled, (state, action) => {
            if (action.payload) {
                state.account.groups = state.account.groups?.map((group) =>
                    group.id === action.payload.id
                        ? {
                              ...group,
                              ...action.payload,
                          }
                        : group,
                );
            }
        });
        builder.addCase(updateFilter.fulfilled, (state, action) => {
            state.account.filters = action.meta.arg;
        });
    },
    selectors: {
        selectTodayWorkedTime: (sliceState) => sliceState.todayWorkedTime,
        selectIsCalendarOn: (sliceState) => sliceState.isCalendarOn,
        selectCurrentUserId: (sliceState) => sliceState.account?.id ?? 0,
        selectCurrentTask: (sliceState) => sliceState.account.currentTask,
        selectIsLogging: (sliceState) => sliceState.isLogging,
        selectAccount: (sliceState) => sliceState.account,
        selectAccountFilters: (sliceState) => sliceState.account.filters,
        selectAccountWorkedTime: (sliceState) => sliceState.account.workedTime,
        selectAccountIsEmailValid: (sliceState) => sliceState.account.is_email_valid,
    },
});

export type accountSlice = {
    [accountSlice.name]: ReturnType<typeof accountSlice['reducer']>;
};

export const {
    selectTodayWorkedTime,
    selectIsCalendarOn,
    selectCurrentUserId,
    selectCurrentTask,
    selectIsLogging,
    selectAccount,
    selectAccountFilters,
    selectAccountWorkedTime,
    selectAccountIsEmailValid,
} = accountSlice.selectors;

export const {
    changeIsCalendarOn,
    changeTodayWorkedTime,
    changeCurrentUserId,
    updateCurrentTask,
    clearAccount,
    increaseWorkedTime,
    changeIsEmailValid,
    logoutAccount,
    addUserGroup,
    removeUserGroup,
    updateUserGroup,
    updateUserProjectInvite,
    addUserProjectInvite,
    removeUserProjectInvite,
} = accountSlice.actions;
