import * as React from 'react';
import styles from './Transfer.module.scss';
import Button from '~/components/Button/Button';
import { BUTTON_COLOR } from '~/const';
import SelectField from '~/components/Form/Select/Select';
import { useDispatch } from 'react-redux';
import Checkbox from '~/components/Form/Checkbox/Checkbox';
import AsyncSelectField from '~/components/Form/Select/AsyncSelect';
import { apiTaskAutocomplete, apiTaskMove } from '~/api/task';
import { prepareTaskFromServer } from '~/utils/tasks';
import store, { useAppSelector } from '~/store';
import { selectProjectsDictionary, setPopup } from '~/store/slices/app/slice';
import { selectTask, update } from '~/store/slices/task/slice';

interface IFC {
    taskId: number;
}

const TaskTransfer = ({ taskId }: IFC) => {
    /** @deprecated */
    const dispatch = useDispatch();
    const [checked, setChecked] = React.useState(false);
    const [parentId, setParentId] = React.useState<string | null>(null);
    const [loading, setLoading] = React.useState(false);
    const task = useAppSelector((state) => selectTask(state, taskId));
    const [projectId, setProjectId] = React.useState<string>(`${task.project}`);

    const projects = useAppSelector((state) => selectProjectsDictionary(state));
    const activeProjects: { [value: string]: string } = {};
    for (const [projectId, project] of Object.entries(projects)) {
        if (!project.archive) {
            activeProjects[projectId] = project.title;
        }
    }

    const loadOptions = (value: string) => {
        if (value.length > 2) {
            return apiTaskAutocomplete(projectId || '', encodeURIComponent(value)).then((res) => {
                return res.suggestions.map((sug: { id: number; title: string }) => {
                    return { value: sug.id, label: '#' + sug.id + ' ' + sug.title };
                });
            });
        }
    };

    const saveHandler = () => {
        setLoading(true);
        apiTaskMove(taskId, {
            project_id: projectId,
            parent_task_id: parentId ?? 0,
        })
            .then((res) => {
                const preparedTask = prepareTaskFromServer(res.task, dispatch);
                store.dispatch(update([{ ...task, ...preparedTask }]));
                store.dispatch(setPopup(null));
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <div className={styles.wrapper}>
            <div className={styles.title}>Перенос задачи</div>
            <SelectField
                options={activeProjects}
                onChange={(newValue: ISelectValue) => {
                    setProjectId(newValue!.value);
                }}
                defaultValue={projectId}
                className={styles.select}
                label={'Проект'}
                isSearchable={true}
            />
            <Checkbox label={'Привязка к родителю'} checked={checked} changeHandler={setChecked} />
            {checked && (
                <div style={{ height: '66px' }}>
                    <AsyncSelectField
                        disabled={!projectId}
                        label={'Родитель'}
                        loadOptions={loadOptions}
                        changeHandler={(newValue: ISelectValue) =>
                            newValue && setParentId(newValue.value)
                        }
                        placeholder={'00000 Название задачи'}
                    />
                </div>
            )}
            <Button
                className={styles.button}
                color={BUTTON_COLOR.BLUE}
                onClick={saveHandler}
                disabled={checked ? !parentId : !projectId}
                loading={loading}
            >
                Сохранить
            </Button>
        </div>
    );
};

export default TaskTransfer;
